import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
import { USER_TYPES } from "./CONSTANT";
// import * as Cookies from "js-cookie";

const store = new Vuex.Store({
  state: {
    userData: {},
    distributorID: null,
    distributorName: null,
    userConfig: [],
    is_auth: false, // to store userData status
    isSuperAdmin: false,
    config_customer: {
      perPage: 10,
      currentPage: 1,
      searchQuery: "",
      showverified: false,
    },
    config_supplier: {
      perPage: 10,
      currentPage: 1,
      searchQuery: "",
      showverified: false,
    },
    setting: {
      title: "SET BANNER",
    },
    profileImage: null,
    signatureImage: null,
    stampImage: null,
  },
  mutations: {
    setConfigCustomers(state, data) {
      const D = data.postdata;
      const mem = state.config_customer;
      mem[D["field"]] = D["value"];
      state.config_organisers = mem;
    },

    setConfigSuppliers(state, data) {
      const D = data.postdata;
      const mem = state.config_supplier;
      mem[D["field"]] = D["value"];
      state.config_organisers = mem;
    },

    setAuthStatus(state) {
      state.is_auth = true;
    },
    setAuthUserDetail(state, userData) {
      for (let key of Object.keys(userData)) {
        state.userData[key] = userData[key] !== null ? userData[key] : "";
      }
      console.log("state.userData >>>", state.userData);
      console.log(
        "userType,DISTRIBUTER  >>>",
        state.userData.userType,
        USER_TYPES.DISTRIBUTER
      );

      if (state.userData.userType == USER_TYPES.DISTRIBUTER) {
        state.distributorID = state.userData.id;
        state.distributorName = state.userData.name;
        axios.defaults.headers.common["distributor_id"] = state.distributorID;
      }
      if (state.userData.userType == USER_TYPES.SUPER_ADMIN) {
        state.isSuperAdmin = true;
        axios.defaults.headers.common["distributor_id"] = state.distributorID;
      }
    },
    setDistributorID(state, distributorID) {
      state.distributorID = distributorID;
    },
    REMOVE_DISTRIBUTOR_ID(state, id) {
      state.distributorID = id;
    },

    setDistributorName(state, distributorName) {
      state.distributorName = distributorName;
    },
    removeDistributorName(state, distributorName) {
      state.distributorName = distributorName;
    },

    resetAuthUserDetail(state) {
      for (let key of Object.keys(state?.userData)) {
        state.userData[key] = "";
      }
      state.is_auth = false;
      state.isSuperAdmin = false;
      state.userData.roles = [];
      state.last_activity = null;
      localStorage.removeItem("auth_token");
      axios.defaults.headers.common["Authorization"] = null;
      axios.defaults.headers.common["distributor_id"] = null;
      state.distributorID = null;
      state.distributorName = null;
      state.userConfig = [];
    },
    setSeting(state, Data) {
      if (Data.success == 1) {
        state.setting = Data.data;
      }
    },
    setProfileImage(state, image) {
      state.profileImage = image;
    },
    setsignatureImageUrl(state, image) {
      state.signatureImage = image;
    },
    setstampImageUrl(state, image) {
      state.stampImage = image;
    },

    SET_USER_CONFIGURATION(state, services) {
      state.userConfig = services;
      console.log("state.userConfig >>>", state.userConfig);
    },
    setAllowNagativeStockForDistributor(state, data){
      state.userData.distributor.has_negative_stock = data.has_negative_stock
      state.userData.has_negative_stock = data.has_negative_stock
    }
  },
  actions: {
    // bindEvents() {
    // socket.on("connect", () => {
    //   socket.emit("todo:list", (res) => {
    //     this.todos = res.data;
    //   });
    // });
    // },

    setConfigCustomers({ commit }, data) {
      commit("setConfigCustomers", data);
    },
    setConfigSuppliers({ commit }, data) {
      commit("setConfigSuppliers", data);
    },
    setAuthStatus({ commit }) {
      commit("setAuthStatus");
    },
    setAuthUserDetail({ commit }, userData) {
      commit("setAuthUserDetail", userData);
    },

    setDistributorID({ commit }, distributorID) {
      commit("setDistributorID", distributorID);
    },

    removeDitributorID({ commit }, distributorID) {
      commit("REMOVE_DISTRIBUTOR_ID", distributorID);
    },

    setDistributorName({ commit }, distributorName) {
      commit("setDistributorName", distributorName);
    },

    removeDistributorName({ commit }, distributorName) {
      commit("removeDistributorName", distributorName);
    },
    
    setAllowNagativeStockForDistributor({ commit }, data) {
      commit("setAllowNagativeStockForDistributor", data);
    },
    setUserConfiguration(context, services) {
      context.commit("SET_USER_CONFIGURATION", services);
    },
    resetAuthUserDetail({ commit }) {
      commit("resetAuthUserDetail");
    },
    
    storeSetting({ commit }) {
      console.log("hello from store.js");
      /**
       *  
          try {
        axios
          .get(`/setting/1`)
          .then((response) => {
            let Data = response.data;
            commit("setSeting", Data);
            this.state.profileImage = response?.data?.data?.imageUrl;
          })
          .catch((error) => {
            console.log("error 1 : >>>", error);
            helper.showDataErrorMsg(error, this);
          });
      } catch (error) {
        console.log("error 2 : >>>", error);
        helper.showDataErrorMsg(error, this);
      }
       */

      if (this.state?.distributorID) {
        try {
          axios
            .get(`/setting/1`)
            .then((response) => {
              let Data = response.data;
              commit("setSeting", Data);
              this.state.profileImage = response?.data?.data?.imageUrl;
            })
            .catch((error) => {
              helper.showDataErrorMsg(error, this);
              // this.$toaster.success(error);
            });
        } catch (error) {
          helper.showDataErrorMsg(error, this);
          this.$toaster.success(error);
        }

        return;
      }
    },
  },
  getters: {
    // isServiceEnabled: (state) => (key) => {
    //   if (state?.userConfig.length) {
    //     let service = state.userConfig.find((service) => {
    //       // console.log("service.key === key ",service.key === key);
    //       service.key === key;
    //     });
    //     return !!service;
    //   }
    // },
    getNegativeStockAllowPermission: (state) => {
      return state.userData.has_negative_stock;
    },

    isServiceEnabled: (state) => (key) => {
      let service = state?.userConfig.find((service) => service.key == key);
      return !!service;
    },

    getConfigCustomers: (state) => {
      return state.config_customer;
    },
    getConfigSuppliers: (state) => {
      return state.config_supplier;
    },
    getAuthUser: (state) => {
      return state.userData;
    },
    getAuthStatus: (state) => {
      return state.is_auth;
    },
    isSuperAdminOnly: (state) => {
      return state.isSuperAdmin;
    },
    getDistributorName : (state) => {
      return state.distributorName;
    }
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
